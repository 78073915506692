@import './styles/cg-theme/theme.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;400&display=swap');

@import './styles/theme-base/variables';
@import './styles/UIkit';
// FIXME: Temp for keeping old inputs (changed with angular material 18)
@import './styles/temp/old-inputs.scss';

html,
body {
  height: 100%;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

// h1,
// h2,
// h3,
// h4,
// h5 {
//   font-family: "Montserrat", sans-serif !important;
// }
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
label {
  font:
    400 14px 'Montserrat',
    sans-serif !important;
}
.mat-drawer-container {
  --mat-sidenav-content-background-color: #fff;
}
.priceAndMenus {
  margin: 0;
  font:
    500 14px/16px 'Open Sans',
    sans-serif !important;
  a {
    color: #000;
    text-decoration: none;
    font:
      500 14px/16px 'Open Sans',
      sans-serif !important;
  }
  a:hover {
    color: #fff;
    text-decoration: none;
    font:
      500 14px/16px 'Open Sans',
      sans-serif !important;
  }
  a:active {
    color: #fff;
  }
}

* {
  box-sizing: border-box;
}

.center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.close {
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
  mat-icon {
    font-size: 20px;
    color: #7a7a7a;
    margin-top: 0;
  }
}

.active {
  color: var(--colorPrimaryOld) !important;
}
.is-active {
  color: #fff;
}

.isActive {
  border: 1px solid var(--colorPrimaryOld) !important;
}

.container {
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px !important;
  }
}
@media (min-width: 992px) {
  .container {
    width: 1363px !important;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1220px !important;
    padding-left: 0;
    padding-right: 0;
  }
}
.ocultar-encontra {
  display: none;
}
.button-cancel {
  border-color: var(--colorPrimaryOld) !important;
  color: var(--colorPrimaryOld) !important;
  width: 112px;
}
.button-ok {
  color: #fff !important;
  width: 112px;
}

.button-stroked-primary {
  border-color: var(--colorPrimaryOld) !important;
  color: var(--colorPrimaryOld) !important;
}
.button-cancel-width {
  border-color: var(--colorPrimaryOld) !important;
  color: var(--colorPrimaryOld) !important;
}
.button-cancel-outline {
  width: 100%;
  border: 1px solid var(--colorPrimaryOld) !important;
  box-shadow: none !important;
  color: var(--colorPrimaryOld) !important;
}
.button-ok-width {
  color: #fff !important;
}
.selectPref {
  min-width: 100% !important;
  margin-top: 30px !important;
  margin-left: 16px !important;
}

.mat-mdc-optgroup-label {
  padding: 0 !important;
}
.mat-mdc-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: transparent;
}
.mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
  padding-left: 15px !important;
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

.mat-expansion-panel-header {
  padding: 0 15px !important;
}
.mat-mdc-optgroup-label {
  font-weight: bold;
  color: var(--colorPrimaryOld);
}
.mat-mdc-option.mat-active {
  background: transparent;
}
.mat-expansion-panel-body {
  padding: 0 !important;
}
//FRANKIE
.row::before {
  content: none !important;
  display: table;
}
// filtro precio
.input-precio .mat-form-field-wrapper {
  & .mat-form-field-flex .mat-form-field-infix {
    width: 100%;
  }
}

// filtro slider
.visor .mat-form-field-wrapper {
  padding: 0;
  .mat-form-field-flex {
    padding: 0;
    .mat-form-field-infix {
      .mat-mdc-input-element::placeholder {
        color: #4e4d4d !important;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        letter-spacing: 0.18px;
        text-align: center;
      }
    }
  }
}

.split-button {
  &-item {
    border-radius: 0px !important;
    height: 40px !important;
    min-height: 40px !important;
    margin-top: 5px !important;
    width: 100% !important;
    line-height: 40px !important;
    .mat-mdc-menu-content {
      padding: 0px !important;
    }
    .mat-mdc-menu-item {
      height: 40px !important;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1.25px;
    }
    .mat-mdc-menu-item:not([disabled]) {
      color: var(--colorPrimaryOld);
    }
  }

  &-size {
    .mat-button-toggle-appearance-standard,
    .mat-button-toggle-label-content {
      line-height: 40px !important;
    }
  }
}
.menu {
  min-width: 170px !important;
  width: 170px !important;
  left: 100px !important;
  overflow: hidden !important;
  margin-left: 15px;
  margin-top: 5px;
  button {
    margin: 0 !important;
    a {
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    min-width: 265px !important;
    width: 300px !important;
    left: 0 !important;
    overflow: hidden !important;
    margin-top: 10px;
  }
}
mat-chip-list {
  &.mat-chip-list.perifericos {
    .mat-chip-list-wrapper {
      margin: 0;
      @media (max-width: 1023px) and (min-width: 375px) {
        display: grid;
        grid-template-columns: 50% 50%;
      }
    }
  }

  &.mat-chip-list.displayImpar {
    .mat-chip-list-wrapper {
      @media (max-width: 1023px) {
        grid-template-columns: 33% 33% 33%;
      }
      @media (max-width: 768px) and (min-width: 375px) {
        grid-template-columns: 50% 50%;

        .Todos {
          grid-column: 1/3;
        }
      }
    }
  }
  .mat-mdc-chip.mat-mdc-standard-chip:after {
    background: #fff !important;
  }
}
.mat-form-field-appearance-outline .mat-form-field-label {
  margin-top: 0 !important;
}
input,
.mat-mdc-select {
  font-size: 14px !important;
}
.mat-progress-bar-buffer {
  background-color: #eee !important;
}
.reserva-element-title {
  line-height: 1.25;
}

.reserva-card {
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  display: grid;
  border: 1px solid #d1d1d1;
  box-shadow: none !important;
  min-height: 95px;
  .titulo {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000;
    border-bottom: 1px solid #d1d1d1;
    padding: 5px;
    text-align: left;
    margin: 0;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #4e4d4d;
    margin: 0;
  }
  b {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #4e4d4d;
  }
  hr {
    border: 0;
    border-top: 1px solid #d1d1d1;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .content {
    padding: 0 5px;
  }
}

.scrollable {
  overflow: auto !important;
}

.mat-mdc-dialog-content {
  overflow: visible !important;
}
