@use '@angular/material' as mat;
@use 'angular-mgl-timeline' as mgl;

@import 'src/styles/theme-base/theme-base.scss';
@import 'src/styles/theme-base/_variables.scss';
@import 'src/styles/_UIkit.scss';
@import './components/components.scss';
@import 'src/styles/theme-base/_variables.scss';

@include mat.core();

$orange-palette: (
  50: var(--colorPrimaryOld),
  100: var(--colorPrimaryOld),
  700: var(--colorPrimaryOld),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    700: rgba(black, 0.87),
  ),
);
$custom-typography: mat.m2-define-typography-config(
  $caption: mat.m2-define-typography-level(12px, 16px, 400),
  $font-family: 'Montserrat, sans-serif',
);
$app-primary: mat.m2-define-palette($orange-palette, 50);
$app-accent: mat.m2-define-palette(mat.$m2-green-palette);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);
$theme: (
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: $custom-typography,
);
$app-theme: mat.m2-define-light-theme($theme);
@include mat.all-component-themes($app-theme);

.theme {
  &_nombreProducto {
    color: var(--colorNombre) !important;
    font-weight: var(--pesoNombre) !important;
  }
  &_precio {
    color: var(--colorPrecio) !important;
    .price-before {
      text-decoration: line-through;
      color: #7a7a7a;
      font-size: 0.8em;
    }
    // font-size: var(--tamanoPrecio) !important;
  }
  &_button {
    font-size: 16px !important;
    height: 40px !important;
    color: white !important;
    width: 170px !important;
    &.limite[disabled] {
      background-color: gray;
    }
  }
}

small {
  mat-icon[svgicon] {
    width: var(--iconoNoResaltado);
    height: var(--iconoNoResaltado);
  }
  color: gray;
}

b,
strong {
  font-weight: 600 !important;
}

h1 {
  font:
    400 24px/30px 'Montserrat',
    sans-serif !important;
  letter-spacing: normal !important;
  margin: 0 !important;
}

h3 {
  font:
    400 16px/20px 'Montserrat',
    sans-serif;
  letter-spacing: normal !important;
  margin: 0 0 16px;
}
