:root {
  .cgt-full-filter-radio {
    display: block;
    width: 100%;

    > * {
      width: 100%;
    }

    label {
      cursor: pointer;
      width: 100%;
      margin-right: 0;
    }
  }
}
