/* $mat-orange: (
  50: #fd611a,
  contrast: (
    50: $black-87-opacity
  )
); */

// Breakpoints
$handset: 360px;
$large-handset: 480px;
$x-large-handset: 600px; // sm
$tablet: 768px;
$large-tablet: 840px;
$desktop: 960px; // md
$large-desktop: 1280px; // lg
$x-large-desktop: 1440px;
$xx-large-desktop: 1920px; // xl
$ultra-hd-desktop: 2560px;
//fuentes
:root {
  --boxShadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --animationSmooth: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 0.999);
  //fuentes
  --fontFamily: 'Montserrat';
  --detalleNoResaltado: 10px;
  --iconoNoResaltado: 14px;
  //precio
  --colorPrecio: #4675b9;
  --tamanoPrecio: 22px;
  //nombre
  --colorNombre: black;
  --tamanoNombre: 22px;
  --radiusCard: 12px;
  --container-radius: 16px;
  //scciones
  --tamanoSeccion: 25px;
  --pesoNombre: 400;

  --colorDarkGrey: #4b4b4d;
  --colorHover: #85858565;
  --colorDisabled: #bdbdbd;
  --colorGrey: #757575;
  --colorOutline: #9e9e9e;
  --colorWhite: #fff;
  --colorBlack: #212121;
  --colorBlackSecondary: #424242;
  --colorPrimaryDisabled: #fe9063;
  --colorSuccess: #00d12a;
  --colorBackground: #eee;
  --colorWhiteSecondary: #fafafa;
  --colorPrimary: #f0320a;
  --colorSecondary: #f0320a;
  --colorPrimaryOld: #f0320a;
  --colorAlert: #ffb800;
  --colorAlertWarning: #fcbb11;
  --colorError: #f40025;
  --colorInfo: #0087d8;
}

// breakpoints
$bpXXL: 1400px;
$bpXL: 1200px;
$bpLG: 992px;
$bpMD: 768px;
$bpXS: 570px;
$bpXXS: 370px;

//layout
$section-margin: 2rem;
