.old-inputs {
  --mdc-checkbox-state-layer-size: 30px;
  --mat-form-field-container-height: 49px;
  --mat-form-field-container-vertical-padding: 6px;
  --mat-form-field-filled-with-label-container-padding-top: 20px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0px;
  --mat-form-field-hover-state-layer-opacity: 0;
  --mat-form-field-focus-state-layer-opacity: 0;

  .mdc-checkbox {
    padding-right: 4px !important;
  }

  .mdc-text-field {
    padding: 0;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-subscript-wrapper {
    line-height: 1.125;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: 13.5px;
  }

  .old-dot {
    .mdc-line-ripple::before {
      border-bottom-style: dashed;
    }
  }

  mat-hint {
    font-size: 11px;
  }
}
