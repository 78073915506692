.brand__warranty__info {
  line-height: 20px;
  span,
  ol,
  ul {
    color: var(--colorBlack);
  }
  a {
    color: var(--colorPrimary);
  }
  strong {
    font-weight: 600;
  }
  p {
    margin: 0;
    margin-bottom: 5px;
    color: var(--colorBlack);
  }
  ol {
    li + li {
      margin-top: 10px;
    }
  }

  .ql__message_warning {
    border: 1px solid var(--colorAlertWarning);
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px 10px;
    gap: 10px;
    p {
      margin: 0;
      color: var(--colorBlack);
    }
    .fa-solid {
      color: var(--colorAlertWarning);
      padding: 10px;
    }
  }
}
