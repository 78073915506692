@import 'src/styles/theme-base/_variables.scss';

.cg {
  &__button {
    border-radius: 8px;
    display: inline-flex;
    padding: 8px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;

    mat-icon {
      user-select: none;
      font-size: 20px;
      height: 20px;
      width: 20px;
    }

    &.disabled {
      cursor: default;
      background-color: var(--colorPrimaryDisabled);
    }

    &--primary {
      color: var(--colorWhite);
      background-color: var(--colorPrimary);
    }

    &--secondary {
      color: var(--colorPrimary);
      border: 1px solid var(--colorPrimary);
    }

    &--tertiary {
      color: var(--colorWhite);
      &:hover {
        background-color: var(--colorHover);
      }
      .material-symbols-outlined {
        color: var(--colorSecondary);
      }
    }
    &--quartary {
      color: var(--colorWhite);

      .material-symbols-outlined {
        color: var(--colorWhite);
      }
    }
    &--quintary {
      color: var(--colorBlack);
      background-color: var(--colorWhite);
      &:hover {
        color: var(--colorPrimary);
      }
    }
    &--sextarian {
      color: var(--colorWhite);
      border: 1px solid var(--colorWhite);
      &:hover {
        background-color: var(--colorWhite);
        color: var(--colorPrimary);
      }
    }
  }
  &__primary-card {
    padding: 14px;
    border-radius: 12px;
    border: 1px solid var(--colorOutline);
    background-color: var(--colorWhite);
    transition: var(--animationSmooth);
    cursor: pointer;
    & > * {
      margin: 0;
      padding: 0;
    }

    &:hover {
      box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.3);
      transform: translateY(-1px);
    }
  }
  &__fondo {
    background-color: var(--colorBackground);
    padding: 16px 0;
  }
  &__card {
    border-radius: var(--radiusCard);
    background-color: var(--colorWhite);
    box-shadow: var(--boxShadow);
  }
  &__border-card {
    border: none !important;
    box-shadow: unset !important;
    background-color: unset !important;
  }
  // cg_fw-100 / cg_fw-200 / cg_fw-300 / ...
  @for $i from 100 through 900 {
    &__fw-#{$i} {
      font-weight: #{$i} !important;
    }
  }

  // cg_ty-h1 / cg_ty-h2 / cg_ty-h3 / ...
  &__ty {
    &-h1 {
      font-size: 30px;
      letter-spacing: 0.5px;
    }

    &-h2 {
      font-size: 24px;
      letter-spacing: 0.2px;
    }

    &-h3 {
      font-size: 20px;
      letter-spacing: 0.15px;
    }

    &-h4 {
      font-size: 18px;
      letter-spacing: 0.5px;
    }

    &-h5 {
      font-size: 16px;
      letter-spacing: 0.25px;
    }

    &-h6 {
      font-size: 14px;
      letter-spacing: 0.25px;
    }

    &-h7 {
      font-size: 14px;
      letter-spacing: 1.5px;
    }

    &-h8 {
      font-size: 14px;
      letter-spacing: 0.5px;
    }

    &-h9 {
      font-size: 12px;
      letter-spacing: 0.4px;
    }

    &-h10 {
      font-size: 10px;
      letter-spacing: 0;
    }

    &-h11 {
      font-size: 10px;
      letter-spacing: 1px;
    }

    &-h12 {
      font-size: 8px;
      letter-spacing: 0;
    }
  }
  @mixin apply-color($color) {
    color: $color;
    background-color: $color;
    border-color: $color;
  }

  &__color {
    &-marca {
      @include apply-color(var(--colorPrimary));
      &.disabled {
        @include apply-color(var(--colorPrimaryDisabled));
      }
    }
    &-marca-secondary {
      background: var(--colorSecondary);
    }
    &-msg {
      &-success {
        @include apply-color(#00d12a);
      }
      &-info {
        @include apply-color(#0087d8);
      }
      &-alert {
        @include apply-color(#fcbb11);
      }
      &-error {
        @include apply-color(#f40025);
      }
    }

    &-neutral {
      &-txt {
        &-primary {
          @include apply-color(#212121);
        }
        &-secondary {
          @include apply-color(#424242);
        }
        &-tertiary {
          @include apply-color(#757575);
        }
      }

      &-bg {
        &-primary {
          @include apply-color(#eeeeee);
        }
        &-secondary {
          @include apply-color(var(--colorWhite));
        }
      }

      &-border {
        @include apply-color(#9e9e9e);
      }
      &-disabled {
        @include apply-color(#bdbdbd);
      }
    }
  }
  &__shadow {
    &-con {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    &-1dp {
      box-shadow:
        0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
    }
    &-2dp {
      box-shadow:
        0px 2px 2px rgba(0, 0, 0, 0.14),
        0px 3px 1px rgba(0, 0, 0, 0.12),
        0px 1px 5px rgba(0, 0, 0, 0.2);
    }
    &-3dp {
      box-shadow:
        0px 3px 4px rgba(0, 0, 0, 0.14),
        0px 3px 3px rgba(0, 0, 0, 0.12),
        0px 1px 8px rgba(0, 0, 0, 0.2);
    }
    &-4dp {
      box-shadow:
        0px 4px 5px rgba(0, 0, 0, 0.14),
        0px 1px 10px rgba(0, 0, 0, 0.12),
        0px 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
}

.txt {
  &_section-name {
    font-size: 26px;
    line-height: 31.69px;
    letter-spacing: 0.5px;
  }
  &_price {
    font-size: 22px;
    letter-spacing: 0.5px;
    line-height: auto;
    margin: 0;
  }
  &_additional {
    font-size: 18px;
    line-height: auto;
    margin: 0;
  }
  &_body {
    font-size: 16px;
    line-height: 19.5px;
    margin: 0;
  }
  &_button {
    font-size: 14px;
    line-height: auto;
    margin: 0;
  }
  &_caption {
    font-size: 12px;
    line-height: 14.63px;
    margin: 0;
  }
  &_quantity {
    font-size: 10px;
    line-height: 12.19px;
    margin: 0;
  }
  &_legals {
    font-size: 8px;
    line-height: 9.75px;
    margin: 0;
  }
}
